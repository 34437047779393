import humps from 'humps'
import { defineAction } from '_utils/redux'
import * as financeServices from '_/services/finance'

export const GET_BUDGET_INSTALLMENTS = defineAction('GET_BUDGET_INSTALLMENTS')
export const GET_TRADESMAN_INSTALLMENTS = defineAction('GET_TRADESMAN_INSTALLMENTS')
export const GET_TRADESMAN_INSTALLMENT_DETAILS = defineAction('GET_TRADESMAN_INSTALLMENT_DETAILS')
export const PAY_BUDGET_INSTALLMENTS = defineAction('PAY_BUDGET_INSTALLMENTS')
export const CREATE_ANTICIPATIONS_TRADESMAN = defineAction('CREATE_ANTICIPATIONS_TRADESMAN')
export const REMOVE_BUDGET_INSTALLMENTS = defineAction('REMOVE_BUDGET_INSTALLMENTS')
export const SET_DEFAULT_BUDGET_INSTALLMENTS_FILTER = defineAction(
  'SET_DEFAULT_BUDGET_INSTALLMENTS_FILTER'
)
export const SET_DEFAULT_BUDGET_RECEIVABLES_FILTER = defineAction(
  'SET_DEFAULT_BUDGET_RECEIVABLES_FILTER'
)
export const UPDATE_BUDGET_INSTALLMENTS_FILTER = defineAction('UPDATE_BUDGET_INSTALLMENTS_FILTER')
export const UPDATE_BUDGET_RECEIVABLES_FILTER = defineAction('UPDATE_BUDGET_RECEIVABLES_FILTER')
export const UPDATE_BUDGET_RECEIVABLES_VOUCHER = defineAction('UPDATE_BUDGET_RECEIVABLES_VOUCHER')
export const GET_FINANCE_OPTIONS = defineAction('GET_FINANCE_OPTIONS')
export const GET_BATCHES = defineAction('GET_BATCHES')
export const GET_INSTALLMENTS_BY_BATCH_ID = defineAction('GET_INSTALLMENTS_BY_BATCH_ID')
export const RELEASE_INSTALLMENTS_BY_BATCH_ID = defineAction('RELEASE_INSTALLMENTS_BY_BATCH_ID')
export const CREATE_BATCH = defineAction('CREATE_BATCH')
export const UPDATE_BATCH = defineAction('UPDATE_BATCH')
export const CANCEL_BATCH_INSTALLMENTS = defineAction('CANCEL_BATCH_INSTALLMENTS')
export const UPLOAD_RETURN_FILE = defineAction('UPLOAD_RETURN_FILE')
export const GET_RETURN_FILES_LIST = defineAction('GET_RETURN_FILES_LIST')
export const DOWNLOAD_RETURN_FILE_PDF = defineAction('DOWNLOAD_RETURN_FILE_PDF')
export const GET_PAYMENT_DATA = defineAction('GET_PAYMENT_DATA')
export const GET_PAYMENT_ANTICIPATIONS_LIST = defineAction('GET_PAYMENT_ANTICIPATIONS_LIST')
export const UPDATE_PAYMENT_ANTICIPATIONS = defineAction('UPDATE_PAYMENT_ANTICIPATIONS')
export const PAY_PAYMENT_ANTICIPATIONS = defineAction('PAY_PAYMENT_ANTICIPATIONS')
export const GET_PAYMENT_ANTICIPATION_DETAILS = defineAction('GET_PAYMENT_ANTICIPATION_DETAILS')
export const GET_FINANCE_GENERIC_PARAMETERS = defineAction('GET_FINANCE_GENERIC_PARAMETERS')
export const GET_ANTICIPATIONS_BANNER_INFO = defineAction('GET_ANTICIPATIONS_BANNER_INFO')
export const GET_TRADESMAN_ANTICIPATIONS = defineAction('GET_TRADESMAN_ANTICIPATIONS')
export const DELETE_TRADESMAN_ANTICIPATION = defineAction('DELETE_TRADESMAN_ANTICIPATION')
export const REMOVE_ANTICIPATIONS_PAYMENTS = defineAction('REMOVE_ANTICIPATIONS_PAYMENTS')
export const GET_VERIFY_UPDATE_ANTICIPATIONS = defineAction('GET_VERIFY_UPDATE_ANTICIPATIONS')
export const UPDATE_BUDGET_INSTALLMENT = defineAction('UPDATE_BUDGET_INSTALLMENT')
export const UPDATE_PAYMENT_INSTALLMENT = defineAction('UPDATE_PAYMENT_INSTALLMENT')
export const GET_VERIFY_INSTALLMENTS_STEP_STATUS = defineAction(
  'GET_VERIFY_INSTALLMENTS_STEP_STATUS'
)
export const GET_VERIFY_TRADESMAN_INSTALLMENTS = defineAction('GET_VERIFY_TRADESMAN_INSTALLMENTS')
export const GET_LIST_BUDGET_RECEIVABLES = defineAction('GET_LIST_BUDGET_RECEIVABLES')
export const POST_CONFIRM_RECEIPTS = defineAction('POST_CONFIRM_RECEIPTS')
export const PATCH_CONFIRM_CANCEL_RECEIPTS = defineAction('PATCH_CONFIRM_CANCEL_RECEIPTS')
export const GET_BUDGET_RECEIVABLE_DETAILS = defineAction('GET_BUDGET_RECEIVABLE_DETAILS')
export const UPDATE_PAYMENT_RECEIVABLE = defineAction('UPDATE_PAYMENT_RECEIVABLE')
export const UPDATE_BUDGET_RECEIVABLE = defineAction('UPDATE_BUDGET_RECEIVABLE')
export const UPDATE_BUDGET_RECEIVABLE_INSTALLMENT_QUANTITY = defineAction(
  'UPDATE_BUDGET_RECEIVABLE_INSTALLMENT_QUANTITY'
)
export const GET_BUDGET_RECEIVABLE_SPREADSHEETS = defineAction('GET_BUDGET_RECEIVABLE_SPREADSHEETS')
export const POST_CONDOLIVRE_ANTICIPATIONS_INFO = defineAction('POST_CONDOLIVRE_ANTICIPATIONS_INFO')
export const PROCESS_CONDOLIVRE_PAYMENTS = defineAction('PROCESS_CONDOLIVRE_PAYMENTS')
export const POST_BLOCK_FINANCE_TRANSFERS_BY_RECEIPTS = defineAction(
  'POST_BLOCK_FINANCE_TRANSFERS_BY_RECEIPTS'
)
export const POST_EXPORT_ASAAS_RECEIVABLES = defineAction('POST_EXPORT_ASAAS_RECEIVABLES')
export const GET_INSTALLMENT_RULES = defineAction('GET_INSTALLMENT_RULES')
export const GET_IS_XML_REQUIRED_TO_FINISH_SERVICE_ORDER = defineAction(
  'GET_IS_XML_REQUIRED_TO_FINISH_SERVICE_ORDER'
)
export const GET_LOG_CHARGE_FILES_LIST = defineAction('GET_LOG_CHARGE_FILES_LIST')
export const UPLOAD_CHARGE_FILE = defineAction('UPLOAD_CHARGE_FILE')
export const GET_CHARGE_FILE_DATA = defineAction('GET_CHARGE_FILE_DATA')

export const getBudgetInstallments = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getBudgetInstallments(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_BUDGET_INSTALLMENTS.ACTION,
    payload: data,
  })

  return data
}

export const getTradesmanInstallments = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getTradesmanInstallments(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_TRADESMAN_INSTALLMENTS.ACTION,
    payload: data,
  })

  return data
}

export const getTradesmanInstallmentsDetails = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getTradesmanInstallmentsDetails(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_TRADESMAN_INSTALLMENT_DETAILS.ACTION,
    payload: data,
  })

  return data
}

export const payBudgetInstallments = payload => async (dispatch, getState) => {
  const data = await financeServices.payBudgetInstallments(getState().user.authToken)(payload)

  dispatch({
    type: PAY_BUDGET_INSTALLMENTS.ACTION,
    payload: async () => data,
  })

  return data
}

export const createAnticipationsTradesman = payload => async (dispatch, getState) => {
  const data = await financeServices.createAnticipationsTradesman(getState().user.authToken)(
    payload
  )

  dispatch({
    type: CREATE_ANTICIPATIONS_TRADESMAN.ACTION,
    payload: async () => data,
  })

  return data
}

export const getTradesmanAnticipations = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getTradesmanAnticipations(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_TRADESMAN_ANTICIPATIONS.ACTION,
    payload: data,
  })

  return data
}

export const deleteTradesmanAnticipation = payload => (dispatch, getState) => {
  const data = financeServices.deleteTradesmanAnticipation(getState().user.authToken)(payload)

  dispatch({
    type: DELETE_TRADESMAN_ANTICIPATION.ACTION,
    payload: data,
  })

  return data
}

export const removeAnticipationsPayments = payload => (dispatch, getState) => {
  const data = financeServices.removeAnticipationsPayments(getState().user.authToken)(payload)

  dispatch({
    type: REMOVE_ANTICIPATIONS_PAYMENTS.ACTION,
    payload: data,
  })

  return data
}

export const removeBudgetInstallments = payload => (dispatch, getState) => {
  const data = financeServices.removeBudgetInstallments(getState().user.authToken)(payload)

  dispatch({
    type: REMOVE_BUDGET_INSTALLMENTS.ACTION,
    payload: data,
  })

  return data
}

export const setDefaultBudgetInstallmentsFilter = payload => dispatch =>
  dispatch({
    type: SET_DEFAULT_BUDGET_INSTALLMENTS_FILTER,
    payload,
  })

export const updateBudgetInstallmentsFilter = payload => dispatch =>
  dispatch({
    type: UPDATE_BUDGET_INSTALLMENTS_FILTER,
    payload,
  })

export const getFinanceOptions = () => (dispatch, getState) => {
  const data = financeServices.getFinanceOptions(getState().user.authToken)

  dispatch({
    type: GET_FINANCE_OPTIONS.ACTION,
    payload: data,
  })
}

export const getBatches = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getBatches(getState().user.authToken)({ ...formattedParams })

  dispatch({
    type: GET_BATCHES.ACTION,
    payload: data,
  })

  return data
}

export const getInstallmentsByBatchId = (batchId, params) => (dispatch, getState) => {
  const data = financeServices.getInstallmentsByBatchId(getState().user.authToken)(batchId, params)

  dispatch({
    type: GET_INSTALLMENTS_BY_BATCH_ID.ACTION,
    payload: data,
  })

  return data
}

export const releaseInstallmentsByBatchId = batchId => (dispatch, getState) => {
  const data = financeServices.releaseInstallmentsByBatchId(getState().user.authToken)(batchId)

  dispatch({
    type: RELEASE_INSTALLMENTS_BY_BATCH_ID.ACTION,
    payload: data,
  })

  return data
}

export const updateBatch = (batchId, payload) => (dispatch, getState) => {
  const data = financeServices.updateBatch(getState().user.authToken)(batchId, payload)

  dispatch({
    type: UPDATE_BATCH.ACTION,
    payload: data,
  })

  return data
}

export const cancelBatchInstallments = (batchId, payload) => (dispatch, getState) => {
  const data = financeServices.cancelBatchInstallments(getState().user.authToken)(batchId, payload)

  dispatch({
    type: CANCEL_BATCH_INSTALLMENTS.ACTION,
    payload: data,
  })

  return data
}

export const getReturnFilesList = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getReturnFilesList(getState().user.authToken)({ ...formattedParams })

  dispatch({
    type: GET_RETURN_FILES_LIST.ACTION,
    payload: data,
  })

  return data
}

export const uploadReturnFile = payload => (dispatch, getState) => {
  const data = financeServices.uploadReturnFile(getState().user.authToken)(payload)

  dispatch({
    type: UPLOAD_RETURN_FILE.ACTION,
    payload: data,
  })

  return data
}

export const downloadReturnFilePdf = payload => (dispatch, getState) => {
  const data = financeServices.downloadReturnFilePdf(getState().user.authToken)(payload)

  dispatch({
    type: DOWNLOAD_RETURN_FILE_PDF.ACTION,
    payload: data,
  })

  return data
}

export const getPaymentData = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getPaymentData(getState().user.authToken)({ ...formattedParams })

  dispatch({
    type: GET_PAYMENT_DATA.ACTION,
    payload: data,
  })

  return data
}

export const getPaymentAnticipationsList = params => (dispatch, getState) => {
  const data = financeServices.getPaymentAnticipationsList(getState().user.authToken)({
    pageSize: params.pageSize,
    page: params.page,
    orderBy: params.orderBy,
    ...params,
  })
  dispatch({
    type: GET_PAYMENT_ANTICIPATIONS_LIST.ACTION,
    payload: data,
  })
  return data
}

export const getPaymentAnticipationDetails = anticipationId => (dispatch, getState) => {
  const data = financeServices.getPaymentAnticipationDetails(getState().user.authToken)(
    anticipationId
  )
  dispatch({
    type: GET_PAYMENT_ANTICIPATION_DETAILS.ACTION,
    payload: data,
  })
  return data
}

export const updatePaymentAnticipations = payload => (dispatch, getState) => {
  const data = financeServices.updatePaymentAnticipations(getState().user.authToken)(payload)
  dispatch({
    type: UPDATE_PAYMENT_ANTICIPATIONS.ACTION,
    payload: data,
  })
  return data
}

export const payPaymentAnticipations = payload => (dispatch, getState) => {
  const data = financeServices.payPaymentAnticipations(getState().user.authToken)(payload)
  dispatch({
    type: PAY_PAYMENT_ANTICIPATIONS.ACTION,
    payload: data,
  })
  return data
}

export const getFinanceGenericParameters = () => (dispatch, getState) => {
  const data = financeServices.getFinanceGenericParameters(getState().user.authToken)()
  dispatch({
    type: GET_FINANCE_GENERIC_PARAMETERS.ACTION,
    payload: data,
  })
  return data
}

export const getAnticipationsBannerInfo = () => (dispatch, getState) => {
  const data = financeServices.getAnticipationsBannerInfo(getState().user.authToken)()
  dispatch({
    type: GET_ANTICIPATIONS_BANNER_INFO.ACTION,
    payload: data,
  })
  return data
}

export const getVerifyUpdateAnticipations = anticipationId => (dispatch, getState) => {
  const data = financeServices.getVerifyUpdateAnticipations(getState().user.authToken)(
    anticipationId
  )
  dispatch({
    type: GET_VERIFY_UPDATE_ANTICIPATIONS.ACTION,
    payload: data,
  })
  return data
}

export const getVerifyInstallmentsStepStatus = params => (dispatch, getState) => {
  const data = financeServices.getVerifyInstallmentsStepStatus(getState().user.authToken)(params)
  dispatch({
    type: GET_VERIFY_INSTALLMENTS_STEP_STATUS.ACTION,
    payload: data,
  })
  return data
}

export const getVerifyTradesmanInstallments = serviceOrderId => (dispatch, getState) => {
  const data = financeServices.getVerifyTradesmanInstallments(getState().user.authToken)(
    serviceOrderId
  )
  dispatch({
    type: GET_VERIFY_TRADESMAN_INSTALLMENTS.ACTION,
    payload: data,
  })
  return data
}

export const updatePaymentInstallment = (installmentId, payload) => (dispatch, getState) => {
  const data = financeServices.updatePaymentInstallment(getState().user.authToken)(
    installmentId,
    payload
  )
  dispatch({
    type: UPDATE_PAYMENT_INSTALLMENT.ACTION,
    payload: data,
  })
  return data
}

export const updateBudgetInstallment = (installmentId, payload) => (dispatch, getState) => {
  const data = financeServices.updateBudgetInstallment(getState().user.authToken)(
    installmentId,
    payload
  )
  dispatch({
    type: UPDATE_BUDGET_INSTALLMENT.ACTION,
    payload: data,
  })
  return data
}

export const getListBudgetReceivables = payload => async (dispatch, getState) => {
  const data = await financeServices.getBudgetReceivables(getState().user.authToken)(payload)
  dispatch({
    type: GET_LIST_BUDGET_RECEIVABLES.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateVoucherReceivables = payload => async (dispatch, getState) => {
  const data = await financeServices.updateVoucherReceivables(getState().user.authToken)(payload)
  dispatch({
    type: UPDATE_BUDGET_RECEIVABLES_VOUCHER.ACTION,
    payload: data,
  })
  return data
}

export const setDefaultBudgetReceivablesFilter = payload => dispatch =>
  dispatch({
    type: SET_DEFAULT_BUDGET_RECEIVABLES_FILTER,
    payload,
  })

export const updateListBudgetReceivablesFilter = payload => dispatch =>
  dispatch({
    type: UPDATE_BUDGET_RECEIVABLES_FILTER,
    payload,
  })

export const postConfirmReceipts = payload => (dispatch, getState) => {
  const data = financeServices.postConfirmReceipts(getState().user.authToken)(payload)
  dispatch({
    type: POST_CONFIRM_RECEIPTS.ACTION,
    payload: data,
  })
  return data
}

export const patchCancelConfirmReceipts = payload => (dispatch, getState) => {
  const data = financeServices.patchCancelConfirmReceipts(getState().user.authToken)(payload)
  dispatch({
    type: PATCH_CONFIRM_CANCEL_RECEIPTS.ACTION,
    payload: data,
  })
  return data
}

export const postBlockFinanceTransfersByReceipts = payload => (dispatch, getState) => {
  const data = financeServices.postBlockFinanceTransfersByReceipts(getState().user.authToken)(
    payload
  )
  dispatch({
    type: POST_BLOCK_FINANCE_TRANSFERS_BY_RECEIPTS.ACTION,
    payload: data,
  })
  return data
}

export const getBudgetReceivableDetails = budgetReceivableId => async (dispatch, getState) => {
  const data = await financeServices.getBudgetReceivableDetails(getState().user.authToken)(
    budgetReceivableId
  )
  dispatch({
    type: GET_BUDGET_RECEIVABLE_DETAILS.ACTION,
    payload: async () => data,
  })
  return data
}

export const updatePaymentReceivable = (receivableId, payload) => (dispatch, getState) => {
  const data = financeServices.updatePaymentReceivable(getState().user.authToken)(
    receivableId,
    payload
  )
  dispatch({
    type: UPDATE_PAYMENT_RECEIVABLE.ACTION,
    payload: data,
  })
  return data
}

export const updateBudgetReceivable = (receivableId, payload) => (dispatch, getState) => {
  const data = financeServices.updateBudgetReceivable(getState().user.authToken)(
    receivableId,
    payload
  )
  dispatch({
    type: UPDATE_BUDGET_RECEIVABLE.ACTION,
    payload: data,
  })
  return data
}

export const getBudgetReceivablesSpreadsheets = () => async (dispatch, getState) => {
  const data = await financeServices.getBudgetReceivablesSpreadsheets(getState().user.authToken)()
  dispatch({
    type: GET_BUDGET_RECEIVABLE_SPREADSHEETS.ACTION,
    payload: async () => data,
  })
  return data
}

export const postCondolivreAnticipationsInfo = payload => (dispatch, getState) => {
  const data = financeServices.postCondolivreAnticipationsInfo(getState().user.authToken)(payload)
  dispatch({
    type: POST_CONDOLIVRE_ANTICIPATIONS_INFO.ACTION,
    payload: data,
  })
  return data
}

export const processCondolivrePayments = (batchId, payload) => (dispatch, getState) => {
  const data = financeServices.processCondolivrePayments(getState().user.authToken)(
    batchId,
    payload
  )

  dispatch({
    type: PROCESS_CONDOLIVRE_PAYMENTS.ACTION,
    payload: data,
  })

  return data
}

export const updateReceiptsInstallmentQuantity =
  (receivableId, payload) => async (dispatch, getState) => {
    const data = await financeServices.updateReceiptsInstallmentQuantity(getState().user.authToken)(
      receivableId,
      payload
    )
    dispatch({
      type: UPDATE_BUDGET_RECEIVABLE_INSTALLMENT_QUANTITY.ACTION,
      payload: async () => data,
    })
    return data
  }

export const postExportASAASReceivables = params => async (dispatch, getState) => {
  const data = await financeServices.postExportASAASReceivables(getState().user.authToken)(params)
  dispatch({
    type: POST_EXPORT_ASAAS_RECEIVABLES.ACTION,
    payload: async () => data,
  })
  return data
}

export const getInstallmentRules = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getInstallmentRules(getState().user.authToken)({
    ...formattedParams,
  })

  dispatch({
    type: GET_INSTALLMENT_RULES.ACTION,
    payload: data,
  })

  return data
}

export const getIsXmlRequiredToFinishServiceOrder =
  serviceOrderId => async (dispatch, getState) => {
    const data = await financeServices.getIsXmlRequiredToFinishServiceOrder(
      getState().user.authToken
    )(serviceOrderId)
    dispatch({
      type: GET_IS_XML_REQUIRED_TO_FINISH_SERVICE_ORDER.ACTION,
      payload: async () => data,
    })
    return data
  }

export const getLogChargeFilesList = params => async (dispatch, getState) => {
  const data = await financeServices.getLogChargeFilesList(getState().user.authToken)(params)

  dispatch({
    type: GET_LOG_CHARGE_FILES_LIST.ACTION,
    payload: async () => data,
  })
  return data
}

export const uploadChargeFile = payload => (dispatch, getState) => {
  const data = financeServices.uploadChargeFile(getState().user.authToken)(payload)

  dispatch({
    type: UPLOAD_CHARGE_FILE.ACTION,
    payload: data,
  })

  return data
}

export const getChargeFileData = params => (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = financeServices.getChargeFileData(getState().user.authToken)({ ...formattedParams })

  dispatch({
    type: GET_CHARGE_FILE_DATA.ACTION,
    payload: data,
  })

  return data
}
